import request from './basenet'

const ccreq = {
  /**
   * GET METHOD
   * @param {Object} url
   * @param {Object} params
   */
  get(url, params) {
    const config = { method: 'GET', url: url}
    if (params) config.params = params

    return request(config)
  },

  /**
   * POST METHOD
   * @param {Object} url
   * @param {Object} params
   */
  post(url, params) {
    const config = { method: 'POST', url: url }
    if (params) config.data = params

    return request(config)
  },

  /**
   * PUT METHOD
   * @param {Object} url
   * @param {Object} params
   */
  put(url, params) {
    const config = { method: 'PUT', url: url }
    if (params) config.data = params

    return request(config)
  },

  /**
   * PUT METHOD
   * @param {Object} url
   * @param {Object} params
   */
  patch(url, params) {
    const config = { method: 'PATCH', url: url }
    if (params) config.data = params

    return request(config)
  },

  /**
   * DELETE METHOD
   * @param {Object} url
   * @param {Object} params
   */
  delete(url, params) {
    const config = { method: 'DELETE', url: url }
    if (params) config.params = params

    return request(config)
  }
}

export default ccreq
