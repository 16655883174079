<template>
  <p class="text-container clear" :style="{'padding': pad, 'background': bkg, 'color': c, 'font-size': s, 'border-radius': br, 'font-weight': fw, 'margin': mg, 'text-align': ta, 'line-height': lh}">
    <slot></slot>
  </p>
</template>

<script>
  export default {
    name: 'XText',
    props: {
      c: String,
      s: String,
      bkg: String,
      pad: String,
      br: String,
      fw: String,
      mg: String,
      ta: String,
      lh: String,
    },
    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .text-container {
    position: relative;
    display: inline-block;
  }
</style>
